import React from "react"
import PropTypes from "prop-types"
import Modal from "react-modal"
import YouTubePlayer from "react-player/lib/players/YouTube"
import { FaWindowClose } from "react-icons/fa"

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    backgroundColor: "#000",
    border: "2px solid #fffccc",
    bottom: "auto",
    marginRight: "-50%",
    maxHeight: "80vh",
    maxWidth: "90vw",
    transform: "translate(-50%, -50%)"
  },
  overlay: {
    zIndex: 6,
  },
}

// Make sure to bind modal to your appElement (http://reactcommunity.org/react-modal/accessibility/)
// Modal.setAppElement('.modal')

class modal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modalIsOpen: false,
    }
  }

  openModal = () => {
    this.setState({ modalIsOpen: true })
  }

  afterOpenModal = () => {
    // references are now sync'd and can be accessed.
  }

  closeModal = () => {
    this.setState({
      modalIsOpen: false,
    })
  }

  render() {
    const { videoUrl, title, description, modalButton, className } = this.props
    return (
      <div className="modal">
        <span className={className} onClick={this.openModal}>
          {modalButton}
        </span>
        <Modal
          ariaHideApp={false}
          isOpen={this.state.modalIsOpen}
          onAfterOpen={this.afterOpenModal}
          onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Video Modal"
        >
          <FaWindowClose
            onClick={this.closeModal}
            className="modal__closeButton"
          />
          <div className="modal__content">
            <h1>{title}</h1>
            <div className="modal__descContainer">
              <p>{description}</p>
            </div>
          </div>
          <div
            // ref={videoPlayer => (this.videoPlayer = videoPlayer)}
            className="modal__playerWrapper"
          >
            <YouTubePlayer
              url={`https://www.youtube.com/watch?v=${videoUrl}`}
              playing
              controls
              className="modal__player"
              onError={e => console.log(e)}
            />
          </div>
        </Modal>
      </div>
    )
  }
}

modal.defaultProps = {
  videoUrl: `IBfm8J9u47A`,
  title: `Video Title`,
  description: `Description`,
  className: `modal__button`,
}

modal.propTypes = {
  videoUrl: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  modalButton: PropTypes.node,
  className: PropTypes.string,
}

export default modal
